var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container search" },
    [
      _vm.genreRequirement.indexOf("location") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-location" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.location)
                  )
                ]),
                _c("div", { staticClass: "ui-border-line" }),
                _c("check-box", {
                  attrs: {
                    text: _vm.locationText,
                    click: function() {
                      return _vm.$refs.LocationModal.show()
                    },
                    on: !_vm.formData.location === false,
                    select: ""
                  }
                }),
                _c(
                  "modal",
                  {
                    ref: "LocationModal",
                    attrs: {
                      title: "지역을 선택해주세요.",
                      confirmCb: function(modal) {
                        var selectedKeys = Object.keys(
                          _vm.modalData.location
                        ).filter(function(key) {
                          return _vm.modalData.location[key]
                        })
                        if (selectedKeys.includes("0")) {
                          if (
                            selectedKeys.length === 1 &&
                            selectedKeys[0] === "0"
                          ) {
                            _vm.formData.location = "0"
                          } else {
                            _vm.formData.location = selectedKeys
                              .filter(function(key) {
                                return key !== "0"
                              })
                              .join(", ")
                          }
                        } else {
                          _vm.formData.location = selectedKeys.join(", ")
                        }
                        _vm.inputData.location = Object.assign(
                          {},
                          _vm.modalData.location
                        )
                        modal.hide()
                      },
                      hiddenCb: function() {
                        _vm.modalData.location = Object.assign(
                          {},
                          _vm.inputData.location
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "ul",
                      { attrs: { slot: "body" }, slot: "body" },
                      _vm._l(_vm.$config.constant.location, function(row, key) {
                        return _c(
                          "li",
                          {
                            key: key,
                            staticClass: "text-center",
                            class: { on: _vm.modalData.location[key] },
                            on: {
                              click: function() {
                                if (key === "0") {
                                  _vm.modalData.location[key] = !_vm.modalData
                                    .location[key]
                                  if (_vm.modalData.location[key]) {
                                    for (var k in _vm.modalData.location) {
                                      if (k !== "0") {
                                        _vm.modalData.location[k] = false
                                      }
                                    }
                                  }
                                } else {
                                  _vm.modalData.location[key] = !_vm.modalData
                                    .location[key]
                                  _vm.modalData.location["0"] = false
                                }
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(row) + " "),
                            _c("div", { staticClass: "ui-border-line" })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("genreType") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-genreType" },
              [
                _c("h3", [_vm._v("전문 분야")]),
                _c(
                  "tag-box",
                  _vm._l(_vm.genreTypes, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.inputData.genreType[row] },
                        on: {
                          click: function() {
                            _vm.inputData.genreType[row] = !_vm.inputData
                              .genreType[row]
                            _vm.formData.genreType =
                              _vm.genreTypes
                                .filter(function(row) {
                                  return _vm.inputData.genreType[row]
                                })
                                .join(",") || null
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("subGenre") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-subGenre" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.subGenre)
                  )
                ]),
                _c(
                  "tag-box",
                  _vm._l(_vm.subGenreText, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.inputData.subGenre[key] },
                        on: {
                          click: function() {
                            _vm.inputData.subGenre[key] = !_vm.inputData
                              .subGenre[key]
                            _vm.formData.subGenre =
                              _vm.subGenreText
                                .filter(function(row, key) {
                                  return _vm.inputData.subGenre[key]
                                })
                                .join(",") || null
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$lib.t_subGenre(row)) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.selectedLiveCommerce
        ? [
            _c(
              "div",
              { staticClass: "search-subGenre" },
              [
                _c("h3", [_vm._v("쇼호스트 전문 분야")]),
                _c(
                  "tag-box",
                  _vm._l(_vm.showHostText, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.inputData.showHost[key] },
                        on: {
                          click: function() {
                            _vm.inputData.showHost[key] = !_vm.inputData
                              .showHost[key]
                            _vm.formData.showHost =
                              _vm.showHostText
                                .filter(function(row, key) {
                                  return _vm.inputData.showHost[key]
                                })
                                .join(",") || null
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("career") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-career" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.career)
                  )
                ]),
                _c("div", { staticClass: "ui-border-line" }),
                _c(
                  "div",
                  { staticClass: "ui-two-box" },
                  [
                    _c("check-box", {
                      attrs: {
                        text: _vm.formData.careerStart
                          ? _vm.$config.constant.career[
                              _vm.formData.careerStart
                            ]
                          : "최소 기간",
                        click: function() {
                          _vm.modalData.careerType = "careerStart"
                          _vm.modalData.career = _vm.inputData.careerStart
                          _vm.$refs.CareerModal.show()
                        },
                        on: _vm.formData.careerStart > 0,
                        select: ""
                      }
                    }),
                    _c("check-box", {
                      attrs: {
                        text: _vm.formData.careerEnd
                          ? _vm.$config.constant.career[_vm.formData.careerEnd]
                          : "최대 기간",
                        click: function() {
                          _vm.modalData.careerType = "careerEnd"
                          _vm.modalData.career = _vm.inputData.careerEnd
                          _vm.$refs.CareerModal.show()
                        },
                        on: _vm.formData.careerEnd > 0,
                        select: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "modal",
                  {
                    ref: "CareerModal",
                    attrs: {
                      title: "경력을 선택해주세요.",
                      useFooter: false,
                      hiddenCb: function() {}
                    }
                  },
                  [
                    _c(
                      "ul",
                      { attrs: { slot: "body" }, slot: "body" },
                      _vm._l(_vm.$config.constant.career, function(row, key) {
                        return _c(
                          "li",
                          {
                            key: key,
                            staticClass: "text-center",
                            class: { on: _vm.modalData.career === Number(key) },
                            on: {
                              click: function() {
                                _vm.modalData.career = Number(key)
                                _vm.formData[_vm.modalData.careerType] =
                                  _vm.modalData.career
                                _vm.inputData[_vm.modalData.careerType] =
                                  _vm.modalData.career
                                _vm.$refs.CareerModal.hide()
                              }
                            }
                          },
                          [
                            key > 0
                              ? [
                                  _vm._v(" " + _vm._s(row) + " "),
                                  _c("div", { staticClass: "ui-border-line" })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("teamType") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-teamType" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.teamType)
                  )
                ]),
                _c(
                  "tag-box",
                  _vm._l(_vm.$config.constant.teamType, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on:
                            _vm.formData.teamType &&
                            _vm.formData.teamType.split(",").indexOf(key) !== -1
                        },
                        on: {
                          click: function() {
                            var teamTypeArr = _vm.formData.teamType
                              ? _vm.formData.teamType.split(",")
                              : []
                            var idx = teamTypeArr.indexOf(key)
                            if (idx === -1) {
                              teamTypeArr.push(key)
                            } else {
                              teamTypeArr.splice(idx, 1)
                            }
                            _vm.formData.teamType = teamTypeArr.join(",")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("mcStyle") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-mcStyle" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.mcStyle)
                  )
                ]),
                _c(
                  "tag-box",
                  _vm._l(_vm.$config.constant.mcStyle, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on:
                            _vm.formData.mcStyle &&
                            _vm.formData.mcStyle.split(",").indexOf(row) !== -1
                        },
                        on: {
                          click: function() {
                            var mcStyleArr = _vm.formData.mcStyle
                              ? _vm.formData.mcStyle.split(",")
                              : []
                            var idx = mcStyleArr.indexOf(row)
                            if (idx === -1) {
                              mcStyleArr.push(row)
                            } else {
                              mcStyleArr.splice(idx, 1)
                            }
                            _vm.formData.mcStyle = mcStyleArr.join(",")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("gender") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-gender" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.gender)
                  )
                ]),
                _c(
                  "tag-box",
                  _vm._l(_vm.$config.constant.gender, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on:
                            _vm.formData.gender &&
                            _vm.formData.gender.split(",").indexOf(key) !== -1
                        },
                        on: {
                          click: function() {
                            var genderArr = _vm.formData.gender
                              ? _vm.formData.gender.split(",")
                              : []
                            var idx = genderArr.indexOf(key)
                            if (idx === -1) {
                              genderArr.push(key)
                            } else {
                              genderArr.splice(idx, 1)
                            }
                            _vm.formData.gender = genderArr.join(",")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("age") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-age" },
              [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$config.constant.genreRequirementKeys.age))
                ]),
                _c("div", { staticClass: "ui-border-line" }),
                _c("check-box", {
                  attrs: {
                    text: _vm.ageText,
                    click: function() {
                      return _vm.$refs.ageModal.show()
                    },
                    on: !_vm.formData.age === false,
                    select: ""
                  }
                }),
                _c(
                  "modal",
                  {
                    ref: "ageModal",
                    attrs: {
                      title: "나이대를 선택해주세요.",
                      confirmCb: function(modal) {
                        var selectedKey = Object.keys(_vm.modalData.age).find(
                          function(key) {
                            return _vm.modalData.age[key]
                          }
                        )
                        _vm.formData.age = selectedKey || "0"
                        _vm.inputData.age = Object.assign({}, _vm.modalData.age)
                        modal.hide()
                      },
                      hiddenCb: function() {
                        _vm.modalData.age = Object.assign({}, _vm.inputData.age)
                      }
                    }
                  },
                  [
                    _c(
                      "ul",
                      { attrs: { slot: "body" }, slot: "body" },
                      _vm._l(_vm.$config.constant.age, function(row, key) {
                        return _c(
                          "li",
                          {
                            key: key,
                            staticClass: "text-center",
                            class: { on: _vm.modalData.age[key] },
                            on: {
                              click: function() {
                                for (var i in _vm.modalData.age) {
                                  _vm.modalData.age[i] = i === key
                                }
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(row) + " "),
                            _c("div", { staticClass: "ui-border-line" })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-mt-3",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("bodyHeight") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-bodyHeight" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.bodyHeight)
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.inputData.bodyHeight[0]) +
                      "cm - " +
                      _vm._s(_vm.inputData.bodyHeight[1]) +
                      "cm"
                  )
                ]),
                _c("slider", {
                  attrs: { data: _vm.inputData.bodyHeight, min: 50, max: 250 },
                  on: {
                    cb: function(val) {
                      _vm.formData.bodyHeightStart = val[0]
                      _vm.formData.bodyHeightEnd = val[1]
                      _vm.inputData.bodyHeight[0] = val[0]
                      _vm.inputData.bodyHeight[1] = val[1]
                      _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("bodyTopsize") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-bodyTopsize" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$config.constant.genreRequirementKeys.bodyTopsize
                    )
                  )
                ]),
                _c(
                  "tag-box",
                  _vm._l(_vm.$config.constant.bodyTopsize, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on:
                            _vm.formData.bodyTopsize &&
                            _vm.formData.bodyTopsize.split(",").indexOf(key) !==
                              -1
                        },
                        on: {
                          click: function() {
                            var bodyTopsizeArr = _vm.formData.bodyTopsize
                              ? _vm.formData.bodyTopsize.split(",")
                              : []
                            var idx = bodyTopsizeArr.indexOf(key)
                            if (idx === -1) {
                              bodyTopsizeArr.push(key)
                            } else {
                              bodyTopsizeArr.splice(idx, 1)
                            }
                            _vm.formData.bodyTopsize = bodyTopsizeArr.join(",")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("bodyWeight") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-bodyWeight" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$config.constant.genreRequirementKeys.bodyWeight)
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.inputData.bodyWeight[0]) +
                      "kg - " +
                      _vm._s(_vm.inputData.bodyWeight[1]) +
                      "kg"
                  )
                ]),
                _c("slider", {
                  attrs: { data: _vm.inputData.bodyWeight, min: 20, max: 120 },
                  on: {
                    cb: function(val) {
                      _vm.formData.bodyWeightStart = val[0]
                      _vm.formData.bodyWeightEnd = val[1]
                      _vm.inputData.bodyWeight[0] = val[0]
                      _vm.inputData.bodyWeight[1] = val[1]
                      _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("bodyBottomsize") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-bodyBottomsize" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$config.constant.genreRequirementKeys.bodyBottomsize
                    )
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.inputData.bodyBottomsize[0]) +
                      "in - " +
                      _vm._s(_vm.inputData.bodyBottomsize[1]) +
                      "in"
                  )
                ]),
                _c("slider", {
                  attrs: {
                    data: _vm.inputData.bodyBottomsize,
                    min: 10,
                    max: 40
                  },
                  on: {
                    cb: function(val) {
                      _vm.formData.bodyBottomsizeStart = val[0]
                      _vm.formData.bodyBottomsizeEnd = val[1]
                      _vm.inputData.bodyBottomsize[0] = val[0]
                      _vm.inputData.bodyBottomsize[1] = val[1]
                      _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("bodyShoesize") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-bodyShoesize" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$config.constant.genreRequirementKeys.bodyShoesize
                    )
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.inputData.bodyShoesize[0]) +
                      "cm - " +
                      _vm._s(_vm.inputData.bodyShoesize[1]) +
                      "cm"
                  )
                ]),
                _c("slider", {
                  attrs: {
                    data: _vm.inputData.bodyShoesize,
                    min: 210,
                    max: 300
                  },
                  on: {
                    cb: function(val) {
                      _vm.formData.bodyShoesizeStart = val[0]
                      _vm.formData.bodyShoesizeEnd = val[1]
                      _vm.inputData.bodyShoesize[0] = val[0]
                      _vm.inputData.bodyShoesize[1] = val[1]
                      _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-6",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("category") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-category" },
              [
                _c("h3", [_vm._v("카테고리")]),
                _c(
                  "tag-box",
                  _vm._l(_vm.inputData.categoryData, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on: _vm.formData.category
                            ? row.uid == _vm.formData.category.uid
                            : false
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectCategory(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.categoryName) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("subCategory") !== -1 &&
      _vm.subCategory.length
        ? [
            _c(
              "div",
              { staticClass: "search-subCategory" },
              [
                _c("h3", [_vm._v("하위 카테고리")]),
                _c(
                  "tag-box",
                  _vm._l(_vm.subCategory, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          on: _vm.formData.subCategory
                            ? row.uid == _vm.formData.subCategory.uid
                            : false
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectSubCategory(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.categoryName) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("price") !== -1
        ? [
            _c(
              "div",
              { staticClass: "search-price" },
              [
                _vm._m(0),
                _c("tag-box", [
                  _c("div", { staticClass: "filter_price" }, [
                    _c("div", { staticClass: "filter_price_min" }, [
                      _c("input", {
                        staticClass: "min_price_0",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.$lib.addComma(_vm.formData.priceStart)
                        },
                        on: {
                          input: function($event) {
                            return _vm.checkNumber("priceStart", $event)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "filter_price_max" }, [
                      _c("input", {
                        staticClass: "max_price_0",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.$lib.addComma(_vm.formData.priceEnd)
                        },
                        on: {
                          input: function($event) {
                            return _vm.checkNumber("priceEnd", $event)
                          }
                        }
                      })
                    ])
                  ])
                ])
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1 ui-mb-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("program") !== -1
        ? [
            _c("check-box", {
              staticClass: "search-program",
              attrs: {
                text: _vm.$config.constant.genreRequirementKeys.program,
                click: function() {
                  return (_vm.formData.program =
                    _vm.formData.program === "y" ? null : "y")
                },
                on: _vm.formData.program === "y"
              }
            }),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.genreRequirement.indexOf("artistCheck") !== -1
        ? [
            _c("check-box", {
              staticClass: "search-artistCheck",
              attrs: {
                text: _vm.$config.constant.genreRequirementKeys.artistCheck,
                click: function() {
                  return (_vm.formData.artistCheck =
                    _vm.formData.artistCheck === "y" ? null : "y")
                },
                on: _vm.formData.artistCheck === "y"
              }
            }),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _c("footer-box", {
        attrs: {
          single: false,
          "btn-text": "필터 초기화",
          "submit-text": "검색하기",
          "submit-cb": function() {
            return _vm.searchArtist()
          },
          "btn-cb": function() {
            return _vm.clearFilter()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "filter_titleBox" }, [
      _c("h3", { staticClass: "filter_title" }, [_vm._v("금액")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }