<template>
  <div class="container search">
    <!-- Start : location 지역 -->
    <template v-if="genreRequirement.indexOf('location') !== -1">
      <div class="search-location">
        <h3>{{$config.constant.genreRequirementKeys.location}}</h3>
        <div class="ui-border-line"></div>
      
        <check-box
          :text="locationText"
          :click="() => $refs.LocationModal.show()"
          :on="!formData.location === false"
          select
        >
        </check-box>
      
        <modal
          ref="LocationModal"
          title="지역을 선택해주세요."
          :confirmCb="modal => {
            const selectedKeys = Object.keys(modalData.location).filter(key => modalData.location[key]);
            if (selectedKeys.includes('0')) {
              if (selectedKeys.length === 1 && selectedKeys[0] === '0') {
                formData.location = '0';
              } else {
                formData.location = selectedKeys.filter(key => key !== '0').join(', ');
              }
            } else {
              formData.location = selectedKeys.join(', ');
            }
            inputData.location = Object.assign({}, modalData.location);
            modal.hide();
          }"
          :hiddenCb="() => {
            modalData.location = Object.assign({}, inputData.location);
          }"
        >
          <ul slot="body">
            <li
              class="text-center"
              v-for="(row, key) in $config.constant.location"
              :key="key"
              @click="() => {
                if (key === '0') {
                  modalData.location[key] = !modalData.location[key];
                  if (modalData.location[key]) {
                    for (let k in modalData.location) {
                      if (k !== '0') modalData.location[k] = false;
                    }
                  }
                } else {
                  modalData.location[key] = !modalData.location[key];
                  modalData.location['0'] = false;
                }
              }"
              :class="{ on: modalData.location[key] }"
            >
              {{ row }}
              <div class="ui-border-line"></div>
            </li>
          </ul>
        </modal>
      </div>
      <div class="ui-border-line ui-h-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : location 지역 -->

    <!-- Start : genreType 전문 분야 -->
    <template v-if="genreRequirement.indexOf('genreType') !== -1">
      <div class="search-genreType">
        <h3>전문 분야</h3>
        <tag-box>
          <li
            v-for="(row, key) in genreTypes"
            :key="key"
            :class="{ on: inputData.genreType[row] }"
            @click="() => {
              inputData.genreType[row] = !inputData.genreType[row]
              formData.genreType = genreTypes.filter((row) => inputData.genreType[row]).join(',') || null
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : genreType 전문 분야 -->

    <!-- Start : subGenre 세부 전문 분야 -->
    <template v-if="genreRequirement.indexOf('subGenre') !== -1">
      <div class="search-subGenre">
        <h3>{{$config.constant.genreRequirementKeys.subGenre}}</h3>
        <tag-box>
          <li
            v-for="(row, key) in subGenreText"
            :key="key"
            :class="{ on: inputData.subGenre[key] }"
            @click="() => {
              inputData.subGenre[key] = !inputData.subGenre[key]
              formData.subGenre = subGenreText.filter((row, key) => inputData.subGenre[key]).join(',') || null
            }"
          >
            {{$lib.t_subGenre(row)}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : subGenre 세부 전문 분야 -->

    <!-- Start : showHost 쇼호스트 전문 분야 -->
    <template v-if="selectedLiveCommerce">
      <div class="search-subGenre">
        <h3>쇼호스트 전문 분야</h3>
        <tag-box>
          <li
            v-for="(row, key) in showHostText"
            :key="key"
            :class="{ on: inputData.showHost[key] }"
            @click="() => {
              inputData.showHost[key] = !inputData.showHost[key]
              formData.showHost = showHostText.filter((row, key) => inputData.showHost[key]).join(',') || null
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : showHost 쇼호스트 전문 분야 -->

    <!-- Start : career 경력 -->
    <template v-if="genreRequirement.indexOf('career') !== -1">
      <div class="search-career">
        <h3>{{$config.constant.genreRequirementKeys.career}}</h3>
        <div class="ui-border-line"></div>
        <div class="ui-two-box">
          <check-box
            :text="formData.careerStart ? $config.constant.career[formData.careerStart] : '최소 기간'"
            :click="() => {
              modalData.careerType = 'careerStart'
              modalData.career = inputData.careerStart
              $refs.CareerModal.show()
            }"
            :on="formData.careerStart > 0"
            select
          >
          </check-box>
          <check-box
            :text="formData.careerEnd ? $config.constant.career[formData.careerEnd] : '최대 기간'"
            :click="() => {
              modalData.careerType = 'careerEnd'
              modalData.career = inputData.careerEnd
              $refs.CareerModal.show()
            }"
            :on="formData.careerEnd > 0"
            select
          >
          </check-box>
        </div>

        <modal
          ref="CareerModal"
          title="경력을 선택해주세요."
          :useFooter="false"
          :hiddenCb="() => {}"
        >
          <ul slot="body">
            <li
              class="text-center"
              v-for="(row, key) in $config.constant.career" :key="key"
              @click="() => {
                modalData.career = Number(key)
                formData[modalData.careerType] = modalData.career
                inputData[modalData.careerType] = modalData.career
                $refs.CareerModal.hide()
              }"
              :class="{ on: modalData.career === Number(key) }"
            >
              <template v-if="key > 0">
                {{row}}
                <div class="ui-border-line"></div>
              </template>
            </li>
          </ul>
        </modal>
      </div>

      <div class="ui-border-line ui-h-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : career 경력 -->

    <!-- Start : teamType 팀 종류 -->
    <template v-if="genreRequirement.indexOf('teamType') !== -1">
      <div class="search-teamType">
        <h3>{{$config.constant.genreRequirementKeys.teamType}}</h3>
        <tag-box>
          <li
            v-for="(row, key) in $config.constant.teamType"
            :key="key"
            :class="{ on: formData.teamType && formData.teamType.split(',').indexOf(key) !== -1 }"
            @click="() => {
              let teamTypeArr = formData.teamType ? formData.teamType.split(',') : []
              let idx = teamTypeArr.indexOf(key)
              if (idx === -1) {
                teamTypeArr.push(key)
              } else {
                teamTypeArr.splice(idx, 1)
              }
              formData.teamType = teamTypeArr.join(',')
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : teamType 팀 종류 -->

    <!-- Start : mcStyle 진행 스타일 -->
    <template v-if="genreRequirement.indexOf('mcStyle') !== -1">
      <div class="search-mcStyle">
        <h3>{{$config.constant.genreRequirementKeys.mcStyle}}</h3>
        <tag-box>
          <li
            v-for="(row, key) in $config.constant.mcStyle"
            :key="key"
            :class="{ on: formData.mcStyle && formData.mcStyle.split(',').indexOf(row) !== -1 }"
            @click="() => {
              let mcStyleArr = formData.mcStyle ? formData.mcStyle.split(',') : []
              let idx = mcStyleArr.indexOf(row)
              if (idx === -1) {
                mcStyleArr.push(row)
              } else {
                mcStyleArr.splice(idx, 1)
              }
              formData.mcStyle = mcStyleArr.join(',')
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : mcStyle 진행 스타일 -->

    <!-- Start : gender 성별 -->
    <template v-if="genreRequirement.indexOf('gender') !== -1">
      <div class="search-gender">
        <h3>{{$config.constant.genreRequirementKeys.gender}}</h3>
        <tag-box>
          <li
            v-for="(row, key) in $config.constant.gender"
            :key="key"
            :class="{ on: formData.gender && formData.gender.split(',').indexOf(key) !== -1 }"
            @click="() => {
              let genderArr = formData.gender ? formData.gender.split(',') : []
              let idx = genderArr.indexOf(key)
              if (idx === -1) {
                genderArr.push(key)
              } else {
                genderArr.splice(idx, 1)
              }
              formData.gender = genderArr.join(',')
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : gender 성별 -->

    <!-- Start : age 나이대 -->
    <template v-if="genreRequirement.indexOf('age') !== -1">
      <div class="search-age">
        <h3>{{$config.constant.genreRequirementKeys.age}}</h3>
        <!-- v1.0.0 PF320LP 나이대 UI 변경 : 슬라이더 필터 -> 레이어 팝업 -->
        <div class="ui-border-line"></div>

        <check-box
          :text="ageText"
          :click="() => $refs.ageModal.show()"
          :on="!formData.age === false"
          select
        >
        </check-box>
        <!-- 나이대 선택 팝업 (무관, 10대 미만, 10대, 20대, 30대, 40대, 50대, 60대 이상) -->
        <modal
          ref="ageModal"
          title="나이대를 선택해주세요."
          :confirmCb="modal => {
            const selectedKey = Object.keys(modalData.age).find(key => modalData.age[key]);
            formData.age = selectedKey || '0';
            inputData.age = Object.assign({}, modalData.age);
            modal.hide();
          }"
          :hiddenCb="() => {
            modalData.age = Object.assign({}, inputData.age);
          }"
        >
          <ul slot="body">
            <li
              class="text-center"
              v-for="(row, key) in $config.constant.age"
              :key="key"
              @click="() => {
                for (let i in modalData.age) {
                  modalData.age[i] = i === key;
                }
              }"
              :class="{ on: modalData.age[key] }"
            >
              {{ row }}
              <div class="ui-border-line"></div>
            </li>
          </ul>
        </modal>
      </div>

      <div class="ui-border-line ui-mt-3" style="height: 10px"></div>
    </template>
    <!-- End : age 나이대 -->

    <!-- Start : bodyHeight 키 -->
    <template v-if="genreRequirement.indexOf('bodyHeight') !== -1">
      <div class="search-bodyHeight">
        <h3>{{$config.constant.genreRequirementKeys.bodyHeight}}</h3>
        <p>{{inputData.bodyHeight[0]}}cm - {{inputData.bodyHeight[1]}}cm</p>
        <slider
          :data="inputData.bodyHeight"
          :min="50"
          :max="250"
          @cb="val => {
            formData.bodyHeightStart = val[0]
            formData.bodyHeightEnd = val[1]
            inputData.bodyHeight[0] = val[0]
            inputData.bodyHeight[1] = val[1]
            $forceUpdate()
          }"
        >
        </slider>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : bodyHeight 키 -->

    <!-- Start : bodyTopsize 상의 사이즈 -->
    <template v-if="genreRequirement.indexOf('bodyTopsize') !== -1">
      <div class="search-bodyTopsize">
        <h3>{{$config.constant.genreRequirementKeys.bodyTopsize}}</h3>
        <tag-box>
          <li
            v-for="(row, key) in $config.constant.bodyTopsize"
            :key="key"
            :class="{ on: formData.bodyTopsize && formData.bodyTopsize.split(',').indexOf(key) !== -1 }"
            @click="() => {
              let bodyTopsizeArr = formData.bodyTopsize ? formData.bodyTopsize.split(',') : []
              let idx = bodyTopsizeArr.indexOf(key)
              if (idx === -1) {
                bodyTopsizeArr.push(key)
              } else {
                bodyTopsizeArr.splice(idx, 1)
              }
              formData.bodyTopsize = bodyTopsizeArr.join(',')
            }"
          >
            {{row}}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : bodyTopsize 상의 사이즈 -->

    <!-- Start : bodyWeight 몸무게 -->
    <template v-if="genreRequirement.indexOf('bodyWeight') !== -1">
      <div class="search-bodyWeight">
        <h3>{{$config.constant.genreRequirementKeys.bodyWeight}}</h3>
        <p>{{inputData.bodyWeight[0]}}kg - {{inputData.bodyWeight[1]}}kg</p>
        <slider
          :data="inputData.bodyWeight"
          :min="20"
          :max="120"
          @cb="val => {
            formData.bodyWeightStart = val[0]
            formData.bodyWeightEnd = val[1]
            inputData.bodyWeight[0] = val[0]
            inputData.bodyWeight[1] = val[1]
            $forceUpdate()
          }"
        >
        </slider>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : bodyWeight 몸무게 -->

    <!-- Start : bodyBottomsize 하의 사이즈 -->
    <template v-if="genreRequirement.indexOf('bodyBottomsize') !== -1">
      <div class="search-bodyBottomsize">
        <h3>{{$config.constant.genreRequirementKeys.bodyBottomsize}}</h3>
        <p>{{inputData.bodyBottomsize[0]}}in - {{inputData.bodyBottomsize[1]}}in</p>
        <slider
          :data="inputData.bodyBottomsize"
          :min="10"
          :max="40"
          @cb="val => {
            formData.bodyBottomsizeStart = val[0]
            formData.bodyBottomsizeEnd = val[1]
            inputData.bodyBottomsize[0] = val[0]
            inputData.bodyBottomsize[1] = val[1]
            $forceUpdate()
          }"
        >
        </slider>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : bodyBottomsize 하의 사이즈 -->

    <!-- Start : bodyShoesize 신발 사이즈 -->
    <template v-if="genreRequirement.indexOf('bodyShoesize') !== -1">
      <div class="search-bodyShoesize">
        <h3>{{$config.constant.genreRequirementKeys.bodyShoesize}}</h3>
        <p>{{inputData.bodyShoesize[0]}}cm - {{inputData.bodyShoesize[1]}}cm</p>
        <slider
          :data="inputData.bodyShoesize"
          :min="210"
          :max="300"
          @cb="val => {
            formData.bodyShoesizeStart = val[0]
            formData.bodyShoesizeEnd = val[1]
            inputData.bodyShoesize[0] = val[0]
            inputData.bodyShoesize[1] = val[1]
            $forceUpdate()
          }"
        >
        </slider>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-6" style="height: 10px"></div>
    </template>
    <!-- End : bodyShoesize 신발 사이즈 -->

    <!-- Start v1.0.0 카테고리 추가 -->
    <template v-if="genreRequirement.indexOf('category') !== -1">
      <div class="search-category">
        <h3>카테고리</h3>
        <tag-box>
          <li
            v-for="(row, key) in inputData.categoryData"
            :class="{ on: formData.category ? row.uid == formData.category.uid : false }"
            :key="key"
            @click="selectCategory(row)"
          >
          {{ row.categoryName }}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : 카테고리 -->

    <!-- Start v1.0.0 하위 카테고리 추가 -->
    <template v-if="genreRequirement.indexOf('subCategory') !== -1 && subCategory.length">
      <div class="search-subCategory">
        <h3>하위 카테고리</h3>
        <tag-box>
          <li
            v-for="(row, key) in subCategory"
            :key="key"
            :class="{ on: formData.subCategory ? row.uid == formData.subCategory.uid : false }"
            @click="selectSubCategory(row)"
          >
          {{ row.categoryName }}
          </li>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : 하위 카테고리 -->

    <!-- Start v1.0.0 금액 추가 -->
    <template v-if="genreRequirement.indexOf('price') !== -1">
      <div class="search-price">
        <div class="filter_titleBox">
          <h3 class="filter_title">금액</h3>
          <!-- <div class="filter_price_textBox">
            <p class="leftLabel min_price_text min_priceText_0">0원</p><span>&#126;</span><p class="rightLabel max_price_text max_priceText_0">100,000,000원</p>
          </div> -->
        </div>
        <tag-box>
          <div class="filter_price">
            <div class="filter_price_min">
              <input type="text" class="min_price_0" :value="$lib.addComma(formData.priceStart)" @input="checkNumber('priceStart',$event)">
            </div>
            <div class="filter_price_max">
              <input type="text" class="max_price_0" :value="$lib.addComma(formData.priceEnd)" @input="checkNumber('priceEnd',$event)">
            </div>
          </div>
        </tag-box>
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-1" style="height: 10px"></div>
    </template>
    <!-- End : 금액 -->

    <template v-if="genreRequirement.indexOf('program') !== -1">
      <check-box
        class="search-program"
        :text="$config.constant.genreRequirementKeys.program"
        :click="() => formData.program = formData.program === 'y' ? null : 'y'"
        :on="formData.program === 'y'"
      >
      </check-box>

      <div class="ui-border-line ui-h-1" style="height: 10px"></div>
    </template>

    <template v-if="genreRequirement.indexOf('artistCheck') !== -1">
      <check-box
        class="search-artistCheck"
        :text="$config.constant.genreRequirementKeys.artistCheck"
        :click="() => formData.artistCheck = formData.artistCheck === 'y' ? null : 'y'"
        :on="formData.artistCheck === 'y'"
      >
      </check-box>

      <div class="ui-border-line ui-h-1" style="height: 10px"></div>
    </template>
    <footer-box
      :single="false"
      btn-text="필터 초기화"
      submit-text="검색하기"
      :submit-cb="() => searchArtist()"
      :btn-cb="() => clearFilter()"
    >
    </footer-box>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import Modal from '@/components/common/Modal'
import CheckBox from '@/components/common/CheckBox'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'
import Slider from '@/components/common/Slider'

export default {
  name: 'ArtistSearch',
  components: {
    Icon,
    Modal,
    CheckBox,
    TagBox,
    FooterBox,
    Slider,
  },
  data() {
    const query = this.$route.query
    const genreText = this.$store.state.user.genreText

    const location = Object.keys(this.$config.constant.location).reduce((result, key) => {
      // let text = this.$config.constant.location[key]
      let val = false
      let arr = query.location ? query.location.split(/,\s?/) : []

      if (arr.length) {
        if (arr.indexOf(key) !== -1) val = true
      } else {
        if (key == '0') val = true
      }
      return Object.assign(result, { [key]: val })
    }, {})

    const age = Object.keys(this.$config.constant.age).reduce((result, key) => {
      // let text = this.$config.constant.age[key]
      let val = false
      let arr = query.age ? query.age.split(/,\s?/) : []

      if (arr.length) {
        if (arr.indexOf(key) !== -1) val = true
      } else {
        if (key == '0') val = true
      }

      return Object.assign(result, { [key]: val })
    }, {})

    const artistGenre = String(this.$route.query.genre || '')
    const genreData = genreText.find(row => this.$lib.validGenre(artistGenre, row.genre))
    const subGenreText = genreData?.subGenreText.concat('기타') ?? []
    const subGenreDefault = query.subGenre ? query.subGenre.split(/,\s?/) : []
    const subGenre = subGenreText.reduce(
      (result, row, key) => {
        let val = false
        if (subGenreDefault.indexOf(row) !== -1) val = true
        return Object.assign(result, { [key]: val })
      },
      {}
    )

    const showHostText = this.$config.constant.showHost
    const showHostDefault = query.showHost ? query.showHost.split(',') : []
    const showHost = showHostText.reduce(
      (result, row, key) => {
        let val = false
        if (showHostDefault.indexOf(row) !== -1) val = true
        return Object.assign(result, { [key]: val })
      },
      {}
    )

    const genreTypes = this.$config.constant.genreTypes
    const genreTypeDefault = query.genreType ? query.genreType.split(',') : []
    const genreTypeOptions = genreTypes.reduce((result, row) => {
      let check = false
      if (genreTypeDefault.indexOf(row) !== -1) check = true
      return Object.assign(result, { [row]: check })
    }, {});

    let genre = String(this.$route.query.genre || '').replaceAll(' ', '');
    const index = this.$store.state.user.category.findIndex(x=>{
      return x.categoryName.replaceAll(' ', '')==genre;
    });
    const categoryData = this.$store.state.user.category[index]?.subCategory || [];

    return {
      subGenreText,
      showHostText,
      genreTypes,
      formData: {
        genre: artistGenre,
        location: query.location ? query.location.split(/,\s?/).join(',') : '0',
        subGenre: null,
        showHost: null,
        careerStart: null,
        careerEnd: null,
        mcStyle: null,
        teamType: null,
        gender: null,
        // ageStart: 0,
        // ageEnd: 0,
        bodyHeightStart: 160,
        bodyHeightEnd: 190,
        bodyWeightStart: 45,
        bodyWeightEnd: 75,
        bodyTopsize: null,
        bodyBottomsizeStart: 23,
        bodyBottomsizeEnd: 30,
        bodyShoesizeStart: 230,
        bodyShoesizeEnd: 280,
        program: null,
        artistCheck: null,
        genreType: null,
        priceStart: 0,
        priceEnd: 100000000,
        category: null,
        subCategory: null,
      },
      inputData: {
        location: Object.assign({}, location),
        subGenre: Object.assign({}, subGenre),
        showHost: Object.assign({}, showHost),
        genreType: Object.assign({}, genreTypeOptions),
        careerStart: 0,
        careerEnd: 0,
        age: Object.assign({}, age),
        bodyHeight: [160, 190],
        bodyWeight: [45, 75],
        bodyBottomsize: [23, 30],
        bodyShoesize: [230, 280],
        categoryData: Object.assign({}, categoryData),
      },
      modalData: {
        location: Object.assign({}, location),
        careerType: 'careerStart',
        career: 0,
        age: Object.assign({}, age),
      },
    }
  },
  computed: {
    genreRequirement: {
      get() {
        const genre = this.$route.query.genre

        switch (genre) {
          case '노래':
          case 'MC':
          case '모델':
          case '쇼호스트':
          case '행사용품':
          case '행사패키지':
          case '강연자':
          case '댄스':
          case '연주':
          case '퍼포먼스': 
            return this.$config.constant.genreRequirement[genre]
          default:
            return this.$config.constant.genreRequirement.default
        }
      },
    },
    locationText() {
      return Object.keys(this.inputData.location).reduce((result, key) => {
        if (this.inputData.location[key]) {
          if (result) result += ', '
          result += this.$config.constant.location[key]
        }
        return result
      }, '')
    },
    ageText() {
      return Object.keys(this.inputData.age).reduce((result, key) => {
        if (this.inputData.age[key]) {
          if (result) result += ', '
          result += this.$config.constant.age[key]
        }
        return result
      }, '')
    },
    selectedLiveCommerce() {
      const genre = this.$route.query.genre

      if (genre === '쇼호스트') {
        return true
      }

      const key = '홈쇼핑/라이브커머스'
      const subGenres = this.formData.subGenre?.split(',').map(x=>Number(x)) || []
      return subGenres.indexOf(key) !== -1
    },
    subCategory() {
      return this.formData.category?.subCategory ?? [];
    }
  },
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    setQuery() {
      return Object.keys(this.formData).reduce((result, key) => {

        if (this.formData[key] === null) {
          return result
        }

        if (key === 'location') {
          if (this.formData[key] === '전국' || this.formData[key] === '0') {
            result[key] = ''
            return result
          }
          result[key] = this.formData[key].replace(/,\s?/g, ',')
          return result
        }

        if (key === 'showHost') {
          result[key] = this.selectedLiveCommerce
            ? this.formData[key]
            : undefined

          return result
        }

        if (key === 'age') {
          result[key] = this.formData[key].replace(/,\s?/g, ',')
          return result;
        }

        if(key == 'category' || key == 'subCategory') {
          result[key] = this.formData[key].uid;
          return result;
        }

        if (this.genreRequirement.concat('genre').indexOf( key.replace(/(.+)(Start|End)$/, '$1') ) !== -1) {
          result[key] = this.formData[key]
        }

        return result
      }, {})
    },
    searchArtist() {
      FB.AppEvents.logEvent('click', {desc:`일반회원 > 상세 검색 > 검색결과 목록`})

      const query = this.setQuery()

      this.$router.push({
        path: '/artist/list',
        query,
      })
    },
    checkNumber(key, e) {
      const val = e.target.value.replaceAll(/[^0-9]/g, '');
      e.target.value = val;
      this.formData[key] = Number(val);
      if(this.formData[key] > 100000000) {
        this.formData[key] = 100000000;
      }
      if(this.formData[key] < 0) {
        this.formData[key] = 0
      }

      this.formData[key]
    },
    /**
     * 쿼리스트링에 데이터가 있을 경우
     */
    getData() {
      const query = this.$route.query
      /**
       * @param {'number'|'array'} type
       */
      const splitInputData = (key, type, defaultStart, defaultEnd, min, max) => {
        let startKey = `${key}Start`
        let endKey = `${key}End`
        this.formData[startKey] = Number(query[startKey] || defaultStart)
        if (min && this.formData[startKey] < min) this.formData[startKey] = min
        if (max && this.formData[startKey] > max) this.formData[startKey] = max
        this.formData[endKey] = Number(query[endKey] || defaultEnd)
        if (max && this.formData[endKey] < this.formData[startKey]) this.formData[endKey] = this.formData[startKey]
        if (max && this.formData[endKey] > max) this.formData[endKey] = max
        if (max && this.formData[startKey] > this.formData[endKey]) this.formData[startKey] = this.formData[endKey]
        switch (type) {
          case 'number':
            this.inputData[startKey] = this.formData[startKey]
            this.inputData[endKey] = this.formData[endKey]
            break
          case 'array':
            this.inputData[key] = [this.formData[startKey], this.formData[endKey]]
            break
        }
      }

      if (query.teamType) {
        this.formData.teamType = query.teamType
      }

      if (query.gender) {
        this.formData.gender = query.gender
      }

      if (query.subGenre) {
        this.formData.subGenre = query.subGenre
      }

      if (query.genreType) {
        this.formData.genreType = query.genreType
      }

      if (query.showHost) {
        this.formData.showHost = this.selectedLiveCommerce
          ? query.showHost
          : null
      }

      if (query.mcStyle) {
        this.formData.mcStyle = query.mcStyle
      }

      if (query.bodyTopsize) {
        this.formData.bodyTopsize = query.bodyTopsize
      }

      if (query.genre) {
        this.formData.genre = query.genre
      }

      if (query.program) {
        this.formData.program = query.program
      }

      if (query.artistCheck) {
        this.formData.artistCheck = query.artistCheck
      }

      const index = this.$store.state.user.category.findIndex(x=>{
        return x.categoryName.replaceAll(' ', '')==query.genre;
      });
      const categoryData = this.$store.state.user.category[index]?.subCategory || [];
      if(query.category) {
        const index = categoryData.findIndex(x=>{
          return x.uid == query.category;
        });
        const category = categoryData[index];
        this.selectCategory(category);
        if(query.subCategory && category) {
          const index = category.subCategory.findIndex(x=>{
            return x.uid == query.subCategory;
          });
          this.selectSubCategory(category.subCategory[index])
        }
      }

      if (query.careerStart || query.careerEnd) {
        splitInputData('career', 'number', 0, 0, 0, 13)
      }

      // if (query.ageStart || query.ageEnd) {
      //   splitInputData('age', 'array', 20, 54, 17, 70)
      // }

      if (query.bodyHeightStart || query.bodyHeightEnd) {
        splitInputData('bodyHeight', 'array', 160, 190, 50, 250)
      }

      if (query.bodyWeightStart || query.bodyWeightEnd) {
        splitInputData('bodyWeight', 'array', 45, 75, 20, 120)
      }

      if (query.bodyBottomsizeStart || query.bodyBottomsizeEnd) {
        splitInputData('bodyBottomsize', 'array', 23, 30, 10, 40)
      }

      if (query.bodyShoesizeStart || query.bodyShoesizeEnd) {
        splitInputData('bodyShoesize', 'array', 230, 280, 210, 300)
      }
      if(query.priceStart || query.priceEnd) {
        splitInputData('price', 'array', 0, 100000000);
      }
    },
    selectCategory(row) {
      this.formData.category = row;
      this.formData.subCategory = null;
    },
    selectSubCategory(row) {
      this.formData.subCategory = row;
    },
    clearFilter() {
      const artistGenre = String(this.$route.query.genre || '')
      this.formData = {
        genre: artistGenre,
        location: '0',
        subGenre: null,
        showHost: null,
        careerStart: null,
        careerEnd: null,
        mcStyle: null,
        teamType: null,
        gender: null,
        bodyHeightStart: 160,
        bodyHeightEnd: 190,
        bodyWeightStart: 45,
        bodyWeightEnd: 75,
        bodyTopsize: null,
        bodyBottomsizeStart: 23,
        bodyBottomsizeEnd: 30,
        bodyShoesizeStart: 230,
        bodyShoesizeEnd: 280,
        program: null,
        artistCheck: null,
        genreType: null,
        priceStart: 0,
        priceEnd: 100000000,
        category: null,
        subCategory: null,
        age: '0',
      }
      //location
      this.inputData.location = Object.keys(this.inputData.location).reduce((result, key, index) => {
        if (index === 0) {
          result[key] = true
        } else {
          result[key] = false
        }
        return result
      }, {})
      // modalData.location 첫번째 값만 true 고 나머지는 false 로 설정
      this.modalData.location = Object.keys(this.modalData.location).reduce((result, key, index) => {
        if (index === 0) {
          result[key] = true
        } else {
          result[key] = false
        }
        return result
      }, {})

      // modalData의 첫번째 값만 true 고 나머지는 false 로 설정
      this.modalData.age = Object.keys(this.modalData.age).reduce((result, key, index) => {
        if (index === 0) {
          result[key] = true
        } else {
          result[key] = false
        }
        return result
      }, {})
      this.inputData.genreType = Object.keys(this.inputData.genreType).reduce((result, key, index) => {
          result[key] = false
        return result
      }, {});
      // subGenre
      this.inputData.subGenre = Object.keys(this.inputData.subGenre).reduce((result, key, index) => {
          result[key] = false
        return result
      }, {})
      this.inputData.showHost = Object.assign({}, this.formData.showHost)
      this.inputData.careerStart = this.formData.careerStart
      this.inputData.careerEnd = this.formData.careerEnd
      this.inputData.age = Object.assign({}, this.formData.age)
      this.inputData.bodyHeight = [this.formData.bodyHeightStart, this.formData.bodyHeightEnd]
      this.inputData.bodyWeight = [this.formData.bodyWeightStart, this.formData.bodyWeightEnd]
      this.inputData.bodyBottomsize = [this.formData.bodyBottomsizeStart, this.formData.bodyBottomsizeEnd]
      this.inputData.bodyShoesize = [this.formData.bodyShoesizeStart, this.formData.bodyShoesizeEnd]
      this.modalData.careerType = 'careerStart'
      this.modalData.career = 0
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-two-box {
  overflow: hidden;
  margin: 0 -3rem;
  > div {
    float: left;
    width: 50%;
    &:first-child {
      padding-left: 3rem;
      padding-right: 1.5rem;
      border-right: 1px solid #ececec;
    }
    &:last-child {
      padding-left: 1.5rem;
      padding-right: 3rem;
    }
  }
}

#page h3 {
  font-size: 2.6rem;
}

p {
  margin-bottom: 1.5rem;
  font-size: 2.3rem;
  line-height: 4rem;
  color: #a059b6;
}

// v1.0.0 금액 항목 css 추가
.filter_titleBox {
  display: flex;
  justify-content: space-between;
}
.filter_price_textBox {
  display: flex;
  align-items: center
}

.min_price_text,
.max_price_text {
  margin-bottom: 0;
  font-size: 1.9rem;
}

.filter_price_textBox span {
  font-size: 1.8rem;
  color: #ab54bb;
}

.filter_price {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 0 16px;
}

.filter_price::before {
  content: '~';
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  transform: translate(-50%, -50%);
}

.filter_price .filter_price_min,
.filter_price .filter_price_max {
  flex: 1;
  position: relative;
  padding-right: 18px;
}

.filter_price .filter_price_min input,
.filter_price .filter_price_max input {
  width: 100%;
  padding: 15px 13px;
  border-radius: 7px;
  border: 1px solid #cecece;
  font-size: 18px;
  text-align: right;
}

.filter_price .filter_price_min::after,
.filter_price .filter_price_max::after {
  content: '원';
  position: absolute;
  top: calc(50% + 0.909px);
  right: 0;
  font-size: 18px;
  transform: translateY(-50%);
}
</style>
